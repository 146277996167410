import "./style.css";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import * as dat from "lil-gui";
import CANNON from "cannon";

/**
 * Debug
 */
const gui = new dat.GUI();
const debug = {};

/**
 * Base
 */
// Canvas
const canvas = document.querySelector("canvas.webgl");

// Scene
const scene = new THREE.Scene();
const skyBlueColor = new THREE.Color(0xb6f5ff);
// const skyBlueColor = new THREE.Color(0xb5d7e3);
scene.background = new THREE.Color(skyBlueColor);

//Fog
// const fog = new THREE.Fog("#B5D7E3", 1, 15);
const fog = new THREE.Fog(skyBlueColor, 50, 100);
scene.fog = fog;

//World
const world = new CANNON.World();
world.gravity.set(0, -9.8, 0);
world.allowSleep = true;

let cubesThree = [];
let cubesPhysics = [];

//cubes Material
const pinkMaterial = new THREE.MeshStandardMaterial({
  color: "#E782A2",
  roughness: 1,
  metalness: 0,
});
const turquoiseMaterial = new THREE.MeshStandardMaterial({
  color: "#CAD8C7",
  roughness: 1,
  metalness: 0,
});
const blackMaterial = new THREE.MeshStandardMaterial({
  color: "#303E47",
  roughness: 1,
  metalness: 0,
});
const salmonMaterial = new THREE.MeshStandardMaterial({
  color: "#E7BCB3",
  roughness: 1,
  metalness: 0,
});
const cubesMaterials = [
  pinkMaterial,
  turquoiseMaterial,
  salmonMaterial,
  blackMaterial,
];

//Cubes
debug.rayon = 0.6;
gui.add(debug, "rayon").min(0).max(2).step(0.01);
debug.createCubes = () => {
  const x = (Math.random() - 0.5) * debug.rayon;
  const y = 5;
  const z = (Math.random() - 0.5) * debug.rayon;
  const scale = 0.5;
  //   const scale = Math.random() * 0.5;
  const angleX = Math.random() * Math.PI;

  // Threecube
  const cube = new THREE.Mesh(
    new THREE.BoxBufferGeometry(scale, scale, scale),
    cubesMaterials[Math.floor(Math.random() * cubesMaterials.length)]
  );
  cube.castShadow = true;
  cube.position.set(x, y, z);
  scene.add(cube);
  cubesThree.push(cube);

  //physics cubes
  const cubeShape = new CANNON.Box(
    new CANNON.Vec3(scale * 0.5, scale * 0.5, scale * 0.5)
  );
  const cubeBody = new CANNON.Body({
    mass: 1,
    shape: cubeShape,
    position: new CANNON.Vec3(x, y, z),
  });

  world.addBody(cubeBody);
  cubesPhysics.push(cubeBody);
  setTimeout(() => {
    scene.remove(cube);

    world.remove(cubeBody);
  }, 1000 * 50);
};
gui.add(debug, "createCubes");
setInterval(() => {
  debug.createCubes();
}, 100);

/**
 * Textures
 */
const textureLoader = new THREE.TextureLoader();
const cubeTextureLoader = new THREE.CubeTextureLoader();

const environmentMapTexture = cubeTextureLoader.load([
  "/textures/environmentMaps/0/px.png",
  "/textures/environmentMaps/0/nx.png",
  "/textures/environmentMaps/0/py.png",
  "/textures/environmentMaps/0/ny.png",
  "/textures/environmentMaps/0/pz.png",
  "/textures/environmentMaps/0/nz.png",
]);

/**
 * Floor
 */
const floor = new THREE.Mesh(
  new THREE.PlaneGeometry(100, 100),
  new THREE.MeshStandardMaterial({
    color: skyBlueColor,
    // metalness: 0,
    // roughness: 1,
  })
);
floor.receiveShadow = true;
floor.rotation.x = -Math.PI * 0.5;
scene.add(floor);
const floorShape = new CANNON.Plane();
const floorBody = new CANNON.Body({
  mass: 0,
  shape: floorShape,
});
floorBody.quaternion.setFromAxisAngle(new CANNON.Vec3(-1, 0, 0), Math.PI * 0.5);
world.addBody(floorBody);

/**
 * Lights
 */
const ambientLight = new THREE.AmbientLight(0xb5d7e3, 1);
scene.add(ambientLight);

const directionalLight = new THREE.DirectionalLight(0xffffff, 0.65);
directionalLight.castShadow = true;
directionalLight.shadow.mapSize.set(1024, 1024);
directionalLight.shadow.camera.far = 15;
directionalLight.shadow.camera.left = -7;
directionalLight.shadow.camera.top = 7;
directionalLight.shadow.camera.right = 7;
directionalLight.shadow.camera.bottom = -7;
directionalLight.position.set(5, 5, 5);
// const dirHelp = new THREE.DirectionalLightHelper(directionalLight);
// scene.add(dirHelp);
scene.add(directionalLight);
gui.add(directionalLight, "intensity").min(0).max(1).step(0.01);

gui.add(directionalLight.position, "x").min(-5).max(5).step(0.01);
gui.add(directionalLight.position, "y").min(-5).max(5).step(0.01);
gui.add(directionalLight.position, "z").min(-5).max(5).step(0.01);
gui.add(directionalLight.rotation, "x").min(-Math.PI).max(Math.PI).step(0.01);
gui.add(directionalLight.rotation, "y").min(-Math.PI).max(Math.PI).step(0.01);
gui.add(directionalLight.rotation, "z").min(-Math.PI).max(Math.PI).step(0.01);

/**
 * Sizes
 */
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};

window.addEventListener("resize", () => {
  // Update sizes
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;

  // Update camera
  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();

  // Update renderer
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(
  75,
  sizes.width / sizes.height,
  0.1,
  100
);
camera.position.set(-3, 3, 3);
scene.add(camera);

// Controls
const controls = new OrbitControls(camera, canvas);
controls.enableDamping = true;

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
});
renderer.shadowMap.enabled = true;
renderer.shadowMap.type = THREE.PCFSoftShadowMap;
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

/**
 * Animate
 */
const clock = new THREE.Clock();
let oldElapsedTime = 0;
const tick = () => {
  const elapsedTime = clock.getElapsedTime();
  const deltaTime = elapsedTime - oldElapsedTime;
  oldElapsedTime = elapsedTime;

  //cubes
  if (cubesPhysics.length) {
    cubesThree.forEach((cube, index) => {
      cube.position.copy(cubesPhysics[index].position);
      const rot = cubesPhysics[index].quaternion;
      cube.quaternion.copy(cubesPhysics[index].quaternion);
      // cube.rotation.copy(cubesPhysics[index].quaternion);
      //   cube.rotation.setFromQuaternion(cubesPhysics[index].quaternion);
    });
  }

  //world
  world.step(1 / 60, deltaTime, 3);

  // Update controls
  controls.update();

  // Render
  renderer.render(scene, camera);

  // Call tick again on the next frame
  window.requestAnimationFrame(tick);
};

tick();
